import React from 'react';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Icon from '../components/Icon';
import Content from '../components/Content';
import Layout from '../components/Layout';
import ReadyToGet from '../components/ReadyToGet';

const StyledMain = styled.main`
  .svg-inline--fa {
    color: ${(props) => props.theme.primaryHoverColor};
  }
`;

const StyledHr = styled.hr`
  border-color: ${(props) => props.theme.primaryHoverColor};
`;

export const SingleServiceTemplate = ({
  title,
  description,
  servicesTitle,
  servicesList,
  excerpt,
  body,
  categories = [],
}) => (
  <StyledMain style={{ marginTop: '105px' }} className="Services">
    <Container>
      <Row>
        <Col xs={12}>
          <Link className="mb-5" to="/service-categories/">
            &laquo; All Services
          </Link>
          <article
            itemScope
            itemType="http://schema.org/ServicesPosting"
            className="text-center mb-5"
          >
            {title && (
              <h1 itemProp="title" className="mt-5 mb-0">
                {title}
              </h1>
            )}

            {categories && (
              <>
                {categories.map((cat, index) => (
                  <p className="text-muted small mb-5" key={`${cat.category}-${index}`}>
                    {cat.category}
                    {/* Add a comma on all but last category */}
                    {index !== categories.length - 1 ? ',' : ''}
                  </p>
                ))}
              </>
            )}

            {description && (
              <p itemProp="description" className="h5 mb-5 font-weight-light">
                {description}
              </p>
            )}

            <StyledHr className="mt-4 mb-5 w-25" style={{ borderWidth: '2px' }} />

            {servicesTitle && (
              <h2 itemProp="servicesTitle" className="h4 mb-5">
                {servicesTitle}
              </h2>
            )}

            {servicesList && (
              <Row className="text-left">
                {servicesList.map((item, index) => (
                  <Col xs={12} md={6} key={`service-desc-${index}`} className="mb-5 d-flex">
                    <Icon icon="check-circle" size="2x" />
                    <span className="ml-3">{item.description}</span>
                  </Col>
                ))}
              </Row>
            )}

            <Content source={body} className="text-left" />
          </article>
        </Col>
      </Row>
    </Container>
    <ReadyToGet />
  </StyledMain>
);

// Export Default SingleService for front-end
const SingleService = ({ data: { service, allServices } }) => {
  return (
    <Layout meta={service.frontmatter.meta || false} title={service.frontmatter.title || false}>
      <SingleServiceTemplate {...service} {...service.frontmatter} body={service.html} />
    </Layout>
  );
};

export default SingleService;

export const pageQuery = graphql`
  ## Query for SingleService data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleService($id: String!) {
    service: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        description
        excerpt
        servicesTitle
        servicesList {
          description
        }
        template
        categories {
          category
        }
      }
    }

    allServices: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "services" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
