import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { StaticQuery, Link, graphql } from 'gatsby';
import Button from 'react-bootstrap/Button';

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.backgroundColorSecondary};

  .angle-top-placeholder {
    border-top-color: ${(props) => props.theme.backgroundColor} !important;
  }
`;

const StyledSubtitle = styled.h3`
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

const ReadyToGet = () => (
  <StaticQuery
    query={graphql`
      {
        readyToGet: markdownRemark(fileAbsolutePath: { regex: "/content/sections/ready-to-get/" }) {
          frontmatter {
            title
            subtitle
            ctaText
            ctaHref
          }
          html
        }
      }
    `}
    render={(data) => {
      const { title, subtitle, ctaText, ctaHref } = data.readyToGet.frontmatter;
      return (
        <StyledDiv className="ReadyToGet angle-top">
          <div className="angle-top-placeholder" />
          <Container fluid className="py-5">
            <Row>
              <Col xs={12} className="text-center">
                <h2 className="font-weight-normal">{title}</h2>
                <StyledSubtitle className="font-weight-light mx-auto mb-4">
                  {subtitle}
                </StyledSubtitle>
                <Button as={Link} to={ctaHref} size="lg text-uppercase shadow-lg">
                  {ctaText}
                </Button>
              </Col>
            </Row>
          </Container>
        </StyledDiv>
      );
    }}
  />
);

export default ReadyToGet;
